
import {Options, Vue} from "vue-class-component";

export type SelectionOption = {
  value: string;
  label: string;
}

@Options({
  name: "ListSelection",
  props: {
    label: String,
    options: Array,
    // current selection
    modelValue: String,
  },
  emits: [
    "update:modelValue",
  ],
})
export default class ListSelection extends Vue {
  private options: Array<SelectionOption> = [];
  private label = "";
  private modelValue = "";
}
