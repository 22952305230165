

import {Options, Vue} from "vue-class-component";
import ParameterRenderer from "./ParameterRenderer.vue";
import {InstructionDeclaration, ParameterType} from "@/model/InstructionDeclaration";
import {InstructionValue} from "@/engine/instruction-value";
import {InstructionToolbarAction} from "@/components/hud/tab/rule/InstructionToolbarUtil";

@Options({
  name: "InstructionRenderer",
  props: {
    id: String,
    declaration: /* InstructionDeclaration */ Object,
    argumentResolver: Function,
    readOnly: Boolean,
    toolbarActions: Array,
  },
  emits: [
    "click",
    "arg-changed",
  ],
  components: {
    ParameterRenderer,
  },
})
export default class InstructionRenderer extends Vue {
  id!: string;
  declaration!: InstructionDeclaration;
  argumentResolver!: (name: string, type: ParameterType) => InstructionValue;
  readOnly = true;
  toolBarVisible = false;
  toolbarActions!: Array<InstructionToolbarAction>;

  get parameters(): Array<[string, ParameterType]> {
    return Object.entries(this.declaration.parameters);
  }

  get finalToolbarActions(): Array<InstructionToolbarAction> {
    if (this.toolbarActions == null || this.toolbarActions.length === 0) {
      console.log("no actions registered.");
      return [];
    }
    return [...this.toolbarActions, {
      label: "Schliessen",
      icon: "img/aux/close.svg",
      handler: () => {
        console.log("this", this);
        this.toolBarVisible = false;
      },
    }];
  }

  getArgumentValue(name: string, type: ParameterType): InstructionValue {
    if (this.argumentResolver === null) {
      throw new Error("ArgumentResolver not provided");
    }
    return this.argumentResolver(name, type);
  }

  setArgumentValue(name: string, value: InstructionValue): void {
    this.$emit("arg-changed", {name, value});
  }

  getEditorId(name: string): string {
    return `${this.id}-${name}-param-renderer`;
  }

  isReadOnly() {
    return this.readOnly || this.toolBarVisible;
  }

  longPress() {
    this.toolBarVisible = true;
  }

  closeToolbar() {
    this.toolBarVisible = false;
  }
}
