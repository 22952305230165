
import {Options, Vue} from "vue-class-component";
import {IconLayoutOptions} from "@/components/hud/tab/rule/InstructionToolbarUtil";

@Options({
  name: "OverlayIconEditor",
  props: {
    visible: Boolean,
    iconLayoutOptions: /* IconLayoutOptions */ Object,
  },
  emits: [
    "update:visible",
    "icon-selected",
  ],
})
export default class OverlayIconEditor extends Vue {
  visible!: boolean;
  iconLayoutOptions!: IconLayoutOptions;

  getRowCount(): number {
    return this.iconLayoutOptions.rowCount;
  }

  getColumnCount(): number {
    return this.iconLayoutOptions.rowCount;
  }

  getIcon(row: number, column: number): string {
    const index = Math.min(this.iconLayoutOptions.icons.length - 1, this._getIndex(row, column));
    return this.iconLayoutOptions.icons[index];
  }

  handleClick(row: number, column: number): void {
    const icon = this.getIcon(row, column);
    const index = this._getIndex(row, column);
    this.$emit("icon-selected", {icon, index, row, column});
    this.close();
  }

  _getIndex(row: number, column: number): number {
    return this.getColumnCount() * row + column;
  }

  close() {
    this.$emit("update:visible", false);
  }
}
