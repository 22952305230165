
import {Options, Vue} from "vue-class-component";

@Options({
  emits: {
    click: () => {
      console.log("emit on click?");
    },
  },
})
export default class AddCommandButton extends Vue {
  click() {
    this.$emit("click");
  }
}
