
import {defineComponent, PropType} from "vue";

export default defineComponent({
  name: "SoundEnableModal",
  props: {
    okHandler: Function as PropType<() => void>,
  },
  methods: {
    okClicked() {
      if (this.okHandler != null) {
        this.okHandler();
      }
      this.hide();
    },
    cancelClicked() {
      this.hide();
    },
    hide() {
      this.$emit("on-close");
    },
  },
});
