
import {Options, Vue} from "vue-class-component";
import {ShapeRef} from "@/engine/Shape";
import ShapeElement from "@/components/hud/ShapeElement.vue";
import EmptyShapeElement from "@/components/hud/EmptyShapeElement.vue";

@Options({
  name: "ShapeList",
  components: {
    ShapeElement,
    EmptyShapeElement,
  },
  emits: [
    "shape-selected",
    "plus",
  ],
  props: {
    shapes: Array,
    showPlusButton: {
      type: Boolean,
      default: () => true,
    },
  },
})
export default class ShapeList extends Vue {
  shapes: Array<ShapeRef> = [];
  showPlusButton = true;

  shapeClicked(shape: ShapeRef): void {
    this.$emit("shape-selected", shape);
  }

  plusClicked(): void {
    this.$emit("plus");
  }
}
