
import {defineComponent, PropType} from "vue";
import {ListSelectionEntry} from "@/engine/instruction-value";

export default defineComponent({
  name: "OverlayListSelectionEditor",
  components: {},
  props: {
    visible: Boolean,
    options: Array as PropType<Array<ListSelectionEntry>>,
  },
  emits: [
    "element-selected",
    "update:visible",
  ],
  watch: {},
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    handleClick(option: ListSelectionEntry): void {
      this.$emit("element-selected", option);
      this.close();
    },
  },
});
