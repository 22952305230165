

import {Options, Vue} from "vue-class-component";
import {app} from "@/engine/app";
import {WORLD_CONTEXT_ID} from "@/model/Commands";

@Options({
  name: "UndoRedoToolbar",
})
export default class UndoRedoToolbar extends Vue {
  uiState = app.uiState;

  undo() {
    app.undoManager.undo(WORLD_CONTEXT_ID);
  }

  redo() {
    app.undoManager.redo(WORLD_CONTEXT_ID);
  }

  get canUndo(): boolean {
    return this.uiState.undoRedo.canUndo;
  }

  get canRedo(): boolean {
    return this.uiState.undoRedo.canRedo;
  }
}
