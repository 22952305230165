
import {Options, Vue} from "vue-class-component";

@Options({
  name: "TabHeader",
  emits: [
    "update:modelValue",
  ],
  props: {
    modelValue: String,
  },
})
export default class TabHeader extends Vue {
  currentTab = "design";

  switchTab(tabId: string) {
    this.currentTab = tabId;
    this.$emit("update:modelValue", tabId);
  }
}
