
import {Options, Vue} from "vue-class-component";

@Options({
  name: "FormulaInput",
  props: {
    modelValue: String,
    label: String,
  },
  emits: [
    "update:modelValue",
  ],
})
export default class FormulaInput extends Vue {
  private modelValue = "";
  private label = "";
  // FIXME: Formula validation?
  // FIXME: Work with Formula object?
}
