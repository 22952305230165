
import {Options, Vue} from "vue-class-component";
import {InstructionDeclaration} from "@/model/InstructionDeclaration";
import {Modal} from "bootstrap";
import InstructionRenderer from "@/components/hud/tab/rule/InstructionRenderer.vue";

@Options({
  name: "InstructionDeclarationsModal",
  components: {
    InstructionRenderer,
  },
  props: {
    declarations: Array,
  },
  emits: [
    "selected",
  ],
})
export default class InstructionDeclarationsModal extends Vue {
  declarations!: Array<InstructionDeclaration>;

  private _instance?: Modal;

  get modalInstance() {
    return this._instance != null ? this._instance : new Modal(document.getElementById(this.$el.getAttribute("id"))!);
  }

  hide() {
    this.modalInstance.hide();
  }

  show() {
    this.modalInstance.show();
  }

  _actionSelected(conditionDecl: InstructionDeclaration) {
    this.$emit("selected", conditionDecl);
    this.hide();
  }
}
